import xtremeVC from "@xtreme-controls/vue-components";
import Vue from "vue";
import IdleVue from "idle-vue";
import { InlineSvgPlugin } from "vue-inline-svg";
import i18n from "./plugins/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import packageJson from "../package.json";
import dayjs from "./plugins/dayjs";

const { version } = packageJson;
console.info(`App Version: ${version}`);

Vue.config.productionTip = false;

Vue.use(xtremeVC);
Vue.use(dayjs);
Vue.use(InlineSvgPlugin);
Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    idleTime: 8 * 60 * 60 * 1000, // TODO: should be in env var
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
