import axios from "axios";
import { mapState } from "vuex";

async function guestLogin(customerID, code, type, router, xAuth) {
    const url = `https://${window.location.hostname}/api/byod/getGuestToken`;
    const payload = { customerID, code };
    const options = { headers: { "content-type": "application/json" } };

    try {
        const response = await axios.post(url, payload, options);
        if (!response?.data?.token) {
            return;
        }

        await xAuth.loginWithCustomToken(response.data.token);
        await router.app.$store.dispatch("getLockersFeedback");
        await router.app.$store.dispatch("pushNotifications/getPushNotifications");

        router.push({ name: "customer-are-you-instore", query: { type } });
    } catch (error) {
        console.error("Customer Login Error", error);
        await router.push({ name: "customer-login-error" });
    }
}

export async function loginCheckNative(router, query, xAuth) {
    if (!query.redirect) return;

    const urlParams = new URLSearchParams(decodeURIComponent(query.redirect.substring(2)));
    const customerID = urlParams.get("customerID");
    const code = urlParams.get("code");
    const type = urlParams.get("type");

    if (customerID && code) {
        await guestLogin(customerID, code, type, router, xAuth);
    }
}

export default {
    computed: {
        ...mapState("authentication", ["xAuth"]),
    },
    methods: {
        async loginCheck() {
            await loginCheckNative(this.$router, this.$route.query, this.xAuth);
        },
    },
};
