import Vue from "vue";
import VueRouter from "vue-router";
import { loginGuard, accessGuard } from "@xtreme-vue-utils/router";
import { applyThemeNative } from "@/mixins/applyTheme";
import { loginCheckNative } from "@/mixins/loginCheck";
import store from "../store";
import routes from "./routes";

store.dispatch("authentication/initialise").catch((error) => console.error(error));

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

const { xAuth } = store.state.authentication;

router.beforeEach(loginGuard(xAuth, "/admin/login"));
router.beforeEach(accessGuard(xAuth, "/401"));

router.beforeEach(async (to, _from, next) => {
    await loginCheckNative(router, to.query, xAuth);
    console.info("loginCheckNative has finished");
    next();
});

router.afterEach((to) => {
    if (to?.query?.themeName) {
        applyThemeNative(router.app.$vuetify, to.query);
    }
});

export default router;
