import Vue from "vue";
import Vuex from "vuex";
import themer from "@xtreme-vue-utils/themer";
import authentication from "@/store/modules/authentication";
import pushNotifications from "@/store/modules/pushNotifications";
import opsGenie from "@/store/modules/opsGenie";
import gimmick from "@/store/modules/gimmick";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import dayjs from "dayjs";
import { getEnvString, envInt, envArray } from "@/envUtils";
import themes from "../../public/themes/themes.json";

Vue.use(Vuex);

export const storeModel = {
    state: {
        theme: themes.find((t) => t.name === "default"),
        defaultLocation: undefined,
        locations: [],
        collectionPointName: "Locker 001", // TODO: This needs to come from somewhere
        feedbackFormDelay: 12_000,
        awaitableDispatchTimeout: envInt(getEnvString("$VUE_APP_AWAITABLE_DISPATCH_TIMEOUT", "10_000")),
        languages: envArray(getEnvString("$VUE_APP_I18N_AVAILABLE_LOCALES", "en")),
        lockerStates: [],
        customerOrders: [],
        users: [],
        awaitableCalls: [],
        features: [],
        storeNumber: 1, // set default to 1 to prevent issues
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme;
        },

        clearLockerStates(state) {
            state.lockerStates = [];
        },

        updateLockerStates(state, data) {
            data.forEach((locker) => {
                const info = state.authentication.xAuth?.user?.info;
                if (!locker?.id || ((locker.accessLevel || locker.accessLevel === 0) && locker.accessLevel < info?.accessLevel)) {
                    return;
                }
                // Update
                const lockerIndex = state.lockerStates.findIndex((l) => l.id === locker.id);
                if (lockerIndex !== -1) {
                    Vue.set(state.lockerStates, lockerIndex, locker);
                    return;
                }

                // Addition
                state.lockerStates.push(locker);
            });

            data.forEach((order) => {
                // TODO: || order.orderNumber === 'order number' is a quick and dirty hack
                // to catch header lines in the csv, this should be pushed up to the scheduler
                // with a more elegant solution
                if (!order?.orderNumber || order?.orderNumber === "order number") {
                    return;
                }

                const orderIndex = state.customerOrders.findIndex((o) => o.orderNumber === order.orderNumber);
                if (orderIndex !== -1) {
                    Vue.set(state.customerOrders, orderIndex, order);
                    return;
                }

                // Addition
                state.customerOrders.push(order);
            });
        },

        updateCustomerOrders(state, data) {
            state.customerOrders = data;
        },

        updateUsers(state, data) {
            state.users = data;
        },

        addAwaitableCall(state, call) {
            state.awaitableCalls.push(call);
        },

        removeAwaitableCall(state, uuid) {
            state.awaitableCalls = state.awaitableCalls.filter((c) => c.uuid !== uuid);
        },

        updateFeatures(state, features) {
            for (const feature of features) {
                state.features.push(feature);
            }
        },
        clearAllFeatures(state) {
            state.features = [];
        },
        updateDefaultLocation(state, location) {
            state.defaultLocation = location;
        },
        updateLocations(state, locations) {
            state.locations = locations;
        },
        storeFeedbackFormDelay(state, time) {
            state.feedbackFormDelay = time;
        },
        storeNPSStoreNumber(state, storeNum) {
            state.storeNumber = storeNum;
        },
    },
    actions: {
        receivedFeedback({ commit, state, getters }, msg) {
            if (!msg) return;

            const uuid = msg?.meta?.uuid || msg?.meta?.guid; // hack for backwards compatibility during interim between api and api 2.0

            if (msg?.meta?.response && uuid) {
                const promise = state.awaitableCalls.find((c) => c.uuid === uuid)?.promise;
                if (promise) {
                    promise.resolve(msg);
                    promise.done = true;
                    commit("removeAwaitableCall", uuid);
                }
                return;
            }

            // Otherwise it's an update to one of the below.
            switch (msg.type) {
                case "feedback":
                    commit("updateLockerStates", msg.data);
                    break;
                case "push-notifications":
                    if (msg.action) {
                        break;
                    }
                    if (msg?.meta?.uid !== getters.userId) {
                        break;
                    }
                    commit("pushNotifications/updateNotifications", msg.data);
                    break;
                case "users":
                    commit("updateUsers", msg.data);
                    break;
                case "ocr":
                    commit("updateOcr", msg.data);
                    break;
                case "gimmick":
                    if (msg.action === "play") {
                        commit("gimmick/updateGimmick", msg.data);
                    }
                    break;
                default:
                    break;
            }
        },

        // Generic wrapper function that allows us to await a websocket response
        // Server must add meta.response = true and meta.uuid = <request.meta.uuid> to the response
        awaitDispatch({ commit, dispatch, state }, { action, payload }) {
            let promise;
            const actualPromise = new Promise((resolve, reject) => { promise = { done: false, resolve, reject }; });

            const uuid = uuidv4();

            commit("addAwaitableCall", {
                uuid,
                promise,
            });

            const modifiedPayload = _.cloneDeep(payload);
            modifiedPayload.meta = {
                ...payload.meta,
                uuid,
            };

            dispatch(action, modifiedPayload);

            setTimeout(() => {
                if (promise.done) {
                    return;
                }
                const errMsg = `No matching response packet found for request ${uuid} rejecting promise`;
                console.warn(errMsg);
                promise.reject(errMsg);
                commit("removeAwaitableCall", uuid);
            }, state.awaitableDispatchTimeout);

            return actualPromise;
        },

        getLockersFeedback({ dispatch, getters, rootGetters }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id: rootGetters["authentication/accessLevel"] < 3 ? "*" : getters.lockerId,
                },
                type: "feedback",
                action: "get",
            });
        },

        async getCustomerOrders({ commit, dispatch, getters, rootGetters }) {
            await commit("updateCustomerOrders", []);
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id: rootGetters["authentication/accessLevel"] < 3 ? "*" : getters.lockerId,
                },
                type: "feedback",
                action: "get",
            });
        },

        createReturnsUser({ dispatch, getters }, { order, lockerId, email, phoneNumber, images, allOrders, returnReason }) {
            return dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    images,
                    returnReason,
                    allOrders,
                },
                type: "users",
                action: "create",
                data: [
                    {
                        uid: `${order}:${lockerId}`,
                        info: {
                            type: "guest",
                            id: lockerId,
                            code: order,
                            returns: true,
                            by: email,
                            phoneNumber,
                        },
                    },
                ],
            });
        },
        createCustomerUser({ dispatch, getters }, { order, lockerId, link, age, linkId }) {
            return dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "users",
                action: "create",
                data: [
                    {
                        uid: `${order}:${lockerId}`,
                        info: {
                            type: "guest",
                            id: lockerId,
                            code: order,
                            age,
                            link,
                            linkId,
                        },
                    },
                ],
            });
        },

        createCustomerUserFromQrCode({ dispatch, getters }, { lockerId, qrCode, order }) {
            return dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "users",
                action: "create",
                data: [
                    {
                        uid: qrCode,
                        info: {
                            type: "guest",
                            id: lockerId,
                            code: order,
                        },
                    },
                ],
            });
        },

        orderCollectedFromWarehouse({ dispatch, getters }) {
            dispatch("authentication/send", {
                meta: {
                    id: getters.lockerId,
                    location: getters.location,
                },
                type: "orders",
                action: "update",
                data: [{
                    id: getters.lockerId,
                    orderNumber: getters.orderNumber,
                    ageRestricted: getters.ageRestrictedInt,
                    collectedDate: Date.now(),
                    collected: true,
                    cleared: true,
                    by: "customer",
                }],
            });
        },

        orderCollectedFromBin({ dispatch, getters }, { lockerId }) {
            const locker = getters.lockerState(lockerId);

            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id: lockerId,
                },
                type: "orders",
                action: "update",
                data: [{
                    collected: Date.now(),
                    orderNumber: locker.allocatedTo,
                    by: "admin",
                }],
            });
        },

        orderPassedAgeCheck({ dispatch, getters }, { lockerId }) {
            const locker = getters.lockerState(lockerId);

            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id: lockerId,
                },
                type: "orders",
                action: "update",
                data: [{
                    collected: Date.now(),
                    orderNumber: locker.allocatedTo,
                    by: "admin",
                    ageCheck: true,
                }],
            });
        },

        orderCollectedFromLocker({ dispatch, getters }, { id, by } = {}) {
            const locker = getters.lockerState(id);
            dispatch("authentication/send", {
                meta: {
                    id: id || getters.lockerId,
                    location: getters.location,
                },
                type: "orders",
                action: "update",
                data: [{
                    collected: Date.now(),
                    orderNumber: locker?.allocatedTo || getters.orderNumber,
                    by: by || "customer",
                }],
            });
        },

        orderReallocatedFromLocker({ dispatch, getters }, { previousId, newId, orderNo }) {
            dispatch("authentication/send", {
                meta: {
                    id: previousId,
                    location: getters.location,
                },
                type: "orders",
                action: "update",
                data: [{
                    collected: Date.now(),
                    cleared: true,
                    orderNumber: orderNo,
                    by: `Order re-allocated to ${newId} by ${getters.userEmail}`,
                }],
            });
        },

        orderClearedFromLocker({ dispatch, getters }, { id }) {
            const locker = getters.lockerState(id);
            dispatch("authentication/send", {
                meta: {
                    id,
                    location: getters.location,
                },
                type: "orders",
                action: "update",
                data: [{
                    by: getters.userEmail,
                    cleared: true,
                    orderNumber: locker?.allocatedTo || getters.orderNumber,
                    expired: true,
                }],
            });
        },

        returnClearedFromLocker({ dispatch, getters }, { id }) {
            const locker = getters.lockerState(id);
            dispatch("authentication/send", {
                meta: {
                    id,
                    location: getters.location,
                },
                type: "orders",
                action: "update",
                data: [{
                    by: getters.userEmail,
                    cleared: true,
                    collected: true,
                    orderNumber: locker?.allocatedTo || getters.orderNumber,
                    return: true,
                }],
            });
        },

        adminOpenExplanation({ dispatch, getters }, { id, explanation }) {
            const locker = getters.lockerState(id);
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id,
                },
                type: "commands",
                action: "update",
                data: [{
                    orderNumber: locker.allocatedTo,
                    by: getters.userEmail,
                    orderProcessed: explanation,
                }],
            });
        },

        openSpecificLocker({ dispatch, getters }, { id }) {
            dispatch("authentication/send", {
                type: "commands",
                action: "run",
                meta: {
                    location: getters.location,
                    zone: "bus-01",
                    id,
                },
                data: [
                    {
                        name: id,
                        door: "OpenInstant",
                    },
                ],
            });
        },

        openAssignedLocker({ dispatch, getters }, { id }) {
            dispatch("authentication/send", {
                type: "commands",
                action: "run",
                meta: {
                    location: getters.location,
                    zone: "bus-01",
                    id: getters.lockerId || id,
                },
                data: [
                    {
                        name: getters.lockerId || id,
                        door: getters.includesFeature("instantUnlocks") ? "OpenInstant" : "Open",
                    },
                ],
            });
        },

        async getOcrData({ dispatch, getters }, payload) {
            const response = await dispatch("awaitDispatch", {
                action: "authentication/send",
                payload: {
                    meta: {
                        location: getters.location,
                        id: "*",
                    },
                    type: "ocr",
                    action: "process",
                    data: [payload],
                },
            });

            return response.data[0].exctractedData;
        },

        async updateLockerNotes({ dispatch, getters }, { lockerId, note }) {
            const locker = getters.lockerState(lockerId);
            const updatedLocker = _.cloneDeep(locker);
            const created = dayjs().valueOf();
            const createdBy = getters.userEmail;

            if (!updatedLocker?.notes) {
                updatedLocker.notes = [];
            }

            updatedLocker.notes.push({
                id: uuidv4(),
                note,
                created,
                createdBy,
            });

            try {
                const response = await dispatch("awaitDispatch", {
                    action: "authentication/send",
                    payload: {
                        meta: {
                            location: getters.location,
                        },
                        type: "feedback",
                        action: "update",
                        data: [
                            updatedLocker,
                        ],
                    },
                });

                delete response.meta.response;
                delete response.meta.uuid;
                dispatch("receivedFeedback", response);

                return Promise.resolve();
            } catch (e) {
                return Promise.reject(e);
            }
        },

        sendFeedback({ dispatch, getters }, submission) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "forms",
                action: "create",
                data: [
                    submission,
                ],
            });
        },

        metricCollection({ dispatch, getters }, metric) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                },
                type: "metrics",
                action: "create",
                data: [
                    metric,
                ],
            });
        },
        addFeature({ commit }, features) {
            commit("updateFeatures", features);
        },
        clearFeatures({ commit }) {
            commit("clearAllFeatures");
        },
        sendAllOff({ dispatch, getters }, { id }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    zone: "bus-01",
                    id,
                },
                type: "commands",
                action: "run",
                data: [
                    {
                        off: "all",
                    },
                ],
            });
        },
        sendBankOn({ dispatch, getters }, { id, bank }) {
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    zone: "bus-01",
                    id,
                },
                type: "commands",
                action: "run",
                data: [
                    {
                        on: `${bank}`,
                    },
                ],
            });
        },

        setupLocations({ commit, dispatch, state }) {
            const info = state.authentication.xAuth?.user?.info;
            let location = info?.location;

            if (location) {
                commit("updateDefaultLocation", location);
            }

            if (info?.accessLevel <= 3) {
                if (info?.locations) {
                    commit("updateLocations", info?.locations);
                    if (!location) {
                        location = info?.locations[0];
                        commit("updateDefaultLocation", location);
                        dispatch("updateUserLocation");
                    }
                }
            } else {
                // setup customer location
                location = { text: location, value: location };
                commit("updateDefaultLocation", location);
            }
        },

        changeLocation({ commit, dispatch }, { location }) {
            commit("updateDefaultLocation", location);
            dispatch("updateUserLocation");
            commit("clearLockerStates");
            dispatch("getLockersFeedback");
        },

        setupReturnLocation({ commit, dispatch, getters }, { location }) {
            const returnLocation = { text: location, value: location };
            commit("updateDefaultLocation", returnLocation);
            commit("clearLockerStates");
            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    id: "*",
                },
                type: "feedback",
                action: "get",
            });
        },

        updateUserLocation({ dispatch, getters, state }) {
            const user = state.authentication.xAuth?.user;

            dispatch("authentication/send", {
                meta: {
                    location: getters.location,
                    method: "updateLocation",
                },
                type: "users",
                action: "update",
                data: [{
                    ...user,
                    info: {
                        ...user?.info,
                        location: getters.locationObj,
                    },
                }],
            });
        },

        sendOrderReadySms({ dispatch, getters }, _packet) {
            let schedule;
            const packet = _packet;
            const { location } = getters;
            if (packet.delay) {
                schedule = dayjs().add(packet.delay, "minutes").valueOf();
                delete packet.delay;
            }

            dispatch("authentication/send", {
                meta: {
                    location,
                },
                type: "sms",
                action: "create",
                data: [{
                    ...packet,
                    location,
                    schedule,
                    host: window.location.hostname,
                }],
            });
        },
        setFeedbackFormDelay({ commit }, time) {
            commit("storeFeedbackFormDelay", time);
        },
        setNPSStoreNumber({ commit }, storeNum) {
            commit("storeNPSStoreNumber", storeNum);
        },
    },
    getters: {
        userId: (state) => state.authentication.xAuth?.user?.uid,
        userEmail: (state) => state.authentication.xAuth?.user?.email,
        lockerId: (state) => state.authentication.xAuth?.user?.info?.id,
        ageRestrictedInt: (state) => state.authentication.xAuth?.user?.info?.age,
        ageRestricted: (state) => state.authentication.xAuth?.user?.info?.age === 1,
        orderNumber: (state) => state.authentication.xAuth?.user?.info?.code,
        location: (state) => state.defaultLocation?.value,
        locationObj: (state) => state.defaultLocation,
        lockerArmed: (state) => {
            // When locker is armed it is ready for the user to push on the door.
            const lockerId = state.authentication.xAuth?.user?.info?.id;
            return state.lockerStates.find((l) => l.id === lockerId)?.armed === true;
        },
        lockerState: (state) => (lockerId) => state.lockerStates.find((l) => l.id === lockerId) || {},
        user: (state) => (userId) => state.users.find((u) => u.uid === userId) || {},
        includesFeature: (state) => (val) => state.features.includes(val),
        // TODO: Remove below after suitable soak period (e.g. 2 weeks), currently required for backwards compatibility with existing orders
        orderInWarehouse: (state) => {
            const lockerId = state.authentication.xAuth?.user?.info?.id;
            return state.lockerStates.find((l) => l.id === lockerId)?.type?.toLowerCase() === "warehouse";
        },
        lockerType: (state) => (id) => state.lockerStates.find((l) => l.id === id)?.type?.toLowerCase(),
        formDelay: (state) => state.feedbackFormDelay,
        getStoreNum: (state) => state.storeNumber,
    },
    modules: {
        authentication,
        themer,
        pushNotifications,
        opsGenie,
        gimmick,
    },
};

export default new Vuex.Store(storeModel);
