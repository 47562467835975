// Allowed meta keys:
// hideNavigation (boolean): default is false, true will hide
// requireAuth (boolean): default is false, true will force login
// accessLevel (int): will hide from menu and display 401 access denied if trying to access
// menu (object): with icon and text properties, if object exists it will be added to the menu (filtered by access level)
const routes = [
    {
        path: "/customer/are-you-instore",
        name: "customer-are-you-instore",
        component: () => import(/* webpackChunkName: "customer-are-you-instore" */ "../views/customer/AreYouInstore.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/customer/order-in-warehouse",
        name: "customer-order-in-warehouse",
        component: () => import(/* webpackChunkName: "customer-order-in-warehouse" */ "../views/customer/OrderInWarehouse.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/customer/age-verification",
        name: "customer-age-verification",
        component: () => import(/* webpackChunkName: "customer-age-verification" */ "../views/customer/AgeVerificationRequired.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/customer/unlock",
        name: "customer-unlock",
        component: () => import(/* webpackChunkName: "customer-unlock" */ "../views/customer/Unlock.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/customer/feedback-form",
        name: "customer-feedback-form",
        component: () => import(/* webpackChunkName: "customer-feedback-form" */ "../views/customer/FeedbackForm.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/customer/feedback-confirmation",
        name: "customer-feedback-confirmation",
        component: () => import(/* webpackChunkName: "customer-feedback-confirmation" */ "../views/customer/FeedbackConfirmation.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/customer/login-error",
        name: "customer-login-error",
        component: () => import(/* webpackChunkName: "customer-login-error" */ "../views/customer/LoginError.vue"),
        meta: {},
    },
    {
        path: "/customer/idle-session",
        alias: "/idlesession",
        name: "customer-idle-session",
        component: () => import(/* webpackChunkName: "customer-idle-session" */ "../views/customer/IdleSession.vue"),
        meta: {},
    },
    {
        path: "/admin/lockers",
        alias: ["/", "/mainmenu"],
        name: "admin-lockers",
        component: () => import(/* webpackChunkName: "admin-lockers" */ "../views/admin/Lockers.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/engineers/lockers",
        name: "engineers-lockers",
        component: () => import(/* webpackChunkName: "engineers-lockers" */ "../views/admin/EngineersLockers.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 0,
        },
    },
    {
        path: "/admin/open-reason",
        name: "admin-open-reason",
        component: () => import(/* webpackChunkName: "admin-open-reason" */ "../views/admin/OpenReason.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/locker-notes",
        name: "admin-locker-notes",
        component: () => import(/* webpackChunkName: "admin-locker-notes" */ "../views/admin/LockerNotes.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/locker-notes-new",
        name: "admin-locker-notes-new",
        component: () => import(/* webpackChunkName: "admin-locker-notes-new" */ "../views/admin/LockerNotesNew.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/age-verification",
        name: "admin-age-verification",
        component: () => import(/* webpackChunkName: "admin-age-verification" */ "../views/admin/AgeVerification.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/sms-notification",
        name: "admin-sms-notification",
        component: () => import(/* webpackChunkName: "admin-sms-notification" */ "../views/admin/SmsNotification.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/qr-code-scanner",
        name: "admin-qr-code-scanner",
        component: () => import(/* webpackChunkName: "admin-qr-code-scanner" */ "../views/admin/QrCodeScanner.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/combine-order",
        name: "admin-combine-order",
        component: () => import(/* webpackChunkName: "admin-combine-order" */ "../views/admin/CombineOrder.vue"),
        props: true,
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/does-it-fit",
        name: "admin-does-it-fit",
        component: () => import(/* webpackChunkName: "admin-does-it-fit" */ "../views/admin/DoesItFit.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/notifications",
        name: "admin-notifications",
        component: () => import(/* webpackChunkName: "admin-notifications" */ "../views/admin/Notifications.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/admin/login",
        alias: "/login",
        name: "admin-login",
        component: () => import(/* webpackChunkName: "admin-login" */ "../views/admin/Login.vue"),
        meta: {},
    },
    {
        path: "/admin/phone-auth",
        alias: "/MultiFactorAuth",
        name: "admin-phone-auth",
        component: () => import(/* webpackChunkName: "admin-phone-auth" */ "../views/admin/PhoneAuth.vue"),
        props: true,
        meta: {},
    },
    {
        path: "/admin/reset",
        alias: "/reset",
        name: "admin-reset",
        component: () => import(/* webpackChunkName: "admin-reset" */ "../views/admin/ResetPassword.vue"),
        meta: {},
    },
    {
        path: "/admin/registration",
        alias: "/registration",
        name: "admin-registration",
        component: () => import(/* webpackChunkName: "admin-registration" */ "../views/admin/Registration.vue"),
        meta: {},
    },
    {
        path: "/admin/themes",
        name: "themes",
        component: () => import(/* webpackChunkName: "themes" */ "../views/admin/ThemesList.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 1,
        },
    },
    {
        path: "/401",
        name: "401",
        component: () => import(/* webpackChunkName: "401" */ "../views/401.vue"),
        meta: {},
    },
    {
        path: "/gimmick/signup",
        name: "gimmick-signup",
        component: () => import(/* webpackChunkName: "gimmick-signup" */ "../views/gimmick/Signup.vue"),
        meta: {},
    },
    {
        path: "/gimmick/lockers",
        name: "gimmick-lockers",
        component: () => import(/* webpackChunkName: "gimmick-lockers" */ "../views/gimmick/Lockers.vue"),
        meta: {},
    },
    {
        path: "/gimmick/thanks",
        name: "gimmick-thanks",
        component: () => import(/* webpackChunkName: "gimmick-thanks" */ "../views/gimmick/Thanks.vue"),
        meta: {},
    },
    {
        path: "/gimmick/digital-signage",
        name: "gimmick-digital-signage",
        component: () => import(/* webpackChunkName: "gimmick-digital-signages" */ "../views/gimmick/DigitalSignage.vue"),
        meta: {},
    },
    {
        path: "/gimmick/unlock",
        name: "gimmick-unlock",
        component: () => import(/* webpackChunkName: "gimmick-unlock" */ "../views/gimmick/Unlock.vue"),
        meta: {},
    },
    {
        path: "/returns/return-or-exchange",
        name: "return-or-exchange",
        component: () => import(/* webpackChunkName: "return-or-exchange" */ "../views/returns/ReturnExchange.vue"),
        meta: {},
    },
    {
        path: "/returns/return-reason",
        name: "return-reason",
        component: () => import(/* webpackChunkName: "return-reason" */ "../views/returns/ReturnReason.vue"),
        meta: {},
    },
    {
        path: "/returns/select-locker-size",
        name: "select-locker-size",
        component: () => import(/* webpackChunkName: "select-locker-size" */ "../views/returns/SelectLockerSize.vue"),
        meta: {},
    },
    {
        path: "/returns/returns-details",
        name: "returns-details",
        component: () => import(/* webpackChunkName: "returns-details" */ "../views/returns/ReturnsDetails.vue"),
        meta: {},
    },
    {
        path: "/returns/returns-confirmation",
        name: "returns-confirmation",
        component: () => import(/* webpackChunkName: "returns-confirmation" */ "../views/returns/ReturnsConfirmation.vue"),
        meta: {},
    },
];

export default routes;
