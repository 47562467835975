import i18n from "@/plugins/i18n";
import _merge from "lodash/merge";
import { getEnvString, envInt } from "@/envUtils";
import { v4 as uuidv4 } from "uuid";

export default {
    namespaced: true,
    state: () => ({
        notificationLocale: "en", // TODO: This needs to come from somewhere e.g. store location/country
        notificationsBellInterval: null,
        shakeNotificationsBell: false,
        audioNotifications: false,
        notificationsBellShakeDelay: envInt(getEnvString("$VUE_APP_NOTIFICATIONS_BELL_SHAKE_DELAY", "30_000")),
        notifications: [], // you probably want the userNotifications getter
    }),
    mutations: {
        updateNotifications(state, data) {
            state.notifications = data;
        },

        toggleAudioNotifications(state) {
            state.audioNotifications = !state.audioNotifications;
        },

        updateShakeNotificationsBell(state, value) {
            state.shakeNotificationsBell = value;
        },

        setNotificationsBellInterval(state, callback) {
            state.notificationsBellInterval = callback;
        },

        clearNotificationsBellInterval(state) {
            clearInterval(state.notificationsBellInterval);
            state.notificationsBellInterval = null;
        },
    },
    actions: {
        shakeNotificationsBell({ commit }) {
            commit("updateShakeNotificationsBell", true);
            setTimeout(() => {
                // CSS bell shake animation lasts for 1s with an
                // additional 1s pause at the end of the animation
                // here we will go with 1.2s to be safe in case
                // javascript and css aren't perfectly in sync
                commit("updateShakeNotificationsBell", false);
            }, 1200);
        },

        startNotificationsBell({ commit, dispatch, state }) {
            if (state.notificationsBellInterval !== null) {
                return;
            }
            dispatch("shakeNotificationsBell");

            const bellInterval = setInterval(
                () => dispatch("shakeNotificationsBell"),
                state.notificationsBellShakeDelay,
            );
            commit("setNotificationsBellInterval", bellInterval);
        },

        stopNotificationsBell({ commit }) {
            commit("clearNotificationsBellInterval");
        },

        fetchFromWarehouse({ state, rootState, dispatch, rootGetters }) {
            const translationStrings = {
                lockerId: rootGetters.lockerId,
                collectionPointName: rootState.collectionPointName,
            };

            const heading = i18n.t("warehouse.notification-header", state.notificationLocale);
            const message = i18n.t("warehouse.vocovo-notification", state.notificationLocale, translationStrings);

            dispatch("createNotification", {
                heading,
                body: message,
                meta: {
                    type: "pickup",
                    order: rootGetters.orderNumber,
                },
                vocovo: {
                    message,
                },
            });
        },

        requestAgeVerification({ state, dispatch, rootGetters }) {
            const translationStrings = { lockerId: rootGetters.lockerId };

            const heading = i18n.t("age-verification-required.notification-heading", state.notificationLocale);
            const message = i18n.t("age-verification-required.vocovo-notification", state.notificationLocale, translationStrings);

            dispatch("createNotification", {
                heading,
                body: message,
                meta: {
                    type: "verification",
                    order: rootGetters.orderNumber,
                },
                vocovo: {
                    message,
                },
            });
        },

        callForAssistance({ state, dispatch, rootGetters }) {
            const { lockerId } = rootGetters;
            const message = i18n.t("general.need-help-vocovo", state.notificationLocale, { lockerId });

            const lockerIdIsNumeric = !Number.isNaN(Number(lockerId));
            let heading = i18n.t("notifications-card.label-named-locker-assistance", { id: lockerId });
            if (lockerIdIsNumeric) {
                heading = i18n.t("notifications-card.label-locker-assistance", { id: lockerId });
            }

            dispatch("createNotification", {
                heading,
                meta: {
                    order: rootGetters.orderNumber,
                },
                vocovo: {
                    message,
                },
            });
        },
        exchangeMsg({ dispatch }) {
            const message = i18n.t("returns.return-or-exchange-vocovo");
            const heading = i18n.t("notifications-card.label-exchange-assistance");

            dispatch("createNotification", {
                heading,
                vocovo: {
                    message,
                },
            });
        },

        gimmickAnnouncement({ dispatch }, { user, order }) {
            const heading = `Thank you for playing ${user} from ${order} see what you have won!`;

            dispatch("createNotification", {
                heading,
                meta: {
                    order,
                },
            });
        },

        getPushNotifications({ dispatch, rootGetters }) {
            dispatch(
                "authentication/send",
                {
                    meta: {
                        uid: rootGetters.userId,
                        location: rootGetters.location,
                    },
                    type: "push-notifications",
                    action: "get",
                },
                { root: true },
            );
        },

        processPushNotifications({ dispatch, rootGetters }, { id, action, status, lockerId, order }) {
            const meta = {
                order,
                lockerId,
                assignedTo: rootGetters.userEmail,
                location: rootGetters.location,
            };
            if (action === "assign") {
                meta.assignedTo = rootGetters.userEmail;
            }

            if (status) {
                meta.status = status;
            }
            dispatch(
                "authentication/send",
                {
                    meta: {
                        uid: rootGetters.userId,
                        locations: [rootGetters.location],
                    },
                    type: "push-notifications",
                    action: "process",
                    data: [
                        {
                            id,
                            action: (action === "assign") ? "assign" : "acknowledge",
                            meta,
                        },
                    ],
                },
                { root: true },
            );
        },

        createNotification({ dispatch, rootGetters }, notification) {
            const baseNotification = {
                id: uuidv4(),
                heading: "",
                body: "",
                meta: {
                    lockerId: rootGetters.lockerId,
                    requiresHelp: true,
                    assignedTo: "",
                    order: rootGetters.orderNumber,
                },
                actions: [],
                ackType: "one-off",
            };

            dispatch(
                "authentication/send",
                {
                    meta: {
                        locations: [rootGetters.location],
                    },
                    type: "push-notifications",
                    action: "create",
                    data: [_merge(baseNotification, notification)],
                },
                { root: true },
            );
        },
    },
    getters: {
        userNotifications: (state, _getters, _rootState, rootGetters) => {
            const { userId, location } = rootGetters;

            return state.notifications.filter((n) => {
                const global = n?.notify?.global === true;
                const userSpecific = n?.notify?.uids?.includes(userId);
                const relevantLocation = !location || n?.notify?.locations?.includes(location);
                const vaultSpecific = n?.meta?.requiresHelp === true && relevantLocation;

                return global || userSpecific || vaultSpecific;
            });
        },
        notification: (state) => (notificationId) => state.notifications.find((n) => n.id === notificationId) || {},
    },
};
