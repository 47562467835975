import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { getEnvString, envArray } from "@/envUtils";

const availableLanguages = envArray(getEnvString("$VUE_APP_I18N_AVAILABLE_LOCALES", "en"));

availableLanguages.forEach(async (lang) => {
    try {
        await import(`dayjs/locale/${lang}.js`);
    } catch (error) {
        console.error(`Dayjs language file for ${lang} could not be loaded`, error);
    }
});

dayjs.extend(relativeTime);

export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $dayjs: {
                get() {
                    return dayjs;
                },
            },
        });
    },
};
