import { v4 as uuidv4 } from "uuid";

export default {
    namespaced: true,
    state: {
        token: `demo-${uuidv4()}`,
        gimmick: {},
    },
    mutations: {
        updateGimmick(state, data) {
            [state.gimmick] = data;
        },
    },
    actions: {
        getRandomLockerId({ dispatch, rootGetters }) {
            return dispatch("awaitDispatch", {
                action: "authentication/send",
                payload: {
                    meta: {
                        location: rootGetters.location,
                        id: "*",
                    },
                    type: "gimmick",
                    action: "getRandomLocker",
                },
            }, { root: true });
        },

        requestDigitalSignageUsage({ dispatch, rootGetters }) {
            dispatch("authentication/send", {
                meta: {
                    location: rootGetters.location,
                    id: "*",
                },
                type: "gimmick",
                action: "requestDigitalSignageUsage",
            }, { root: true });
        },

        triggerGimmick({ dispatch, rootGetters }, { lockerId }) {
            dispatch("authentication/send", {
                meta: {
                    location: rootGetters.location,
                    id: "*",
                },
                type: "gimmick",
                action: "play",
                data: [
                    {
                        lockerId,
                    },
                ],
            }, { root: true });
        },

        getGimmickFeedback({ dispatch, rootGetters }) {
            dispatch("authentication/send", {
                meta: {
                    location: rootGetters.location,
                    id: "*",
                },
                type: "gimmick",
                action: "get",
            }, { root: true });
        },
    },
};
