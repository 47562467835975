function getEnvString(stringEnvVar, defaultValue) {
    // Example call: getEnv("$VUE_APP_EXAMPLE_NUMBER", "10_000")
    // After envsubst it will look like this: getEnv("20_000", "10_000")
    // Or like this if the env var has not been defined: getEnv("", "10_000")

    if (defaultValue !== undefined && typeof defaultValue !== "string") {
        // using console.log so it appears in test debug
        // eslint-disable-next-line no-console
        console.log(
            "Warning: Improper use of defaultValue when calling getEnv(), env vars are always a string, please make sure you parse the result of getEnv() appropriately.",
            stringEnvVar,
            defaultValue,
        );
    }

    if (!stringEnvVar.includes("VUE_APP_") && stringEnvVar !== "") {
        return stringEnvVar;
    }

    const envVarName = stringEnvVar.replace("$", "");
    const envVar = process.env[envVarName];

    return envVar || String(defaultValue);
}

function envInt(intString) {
    // Will account for numbers using the numeric separator e.g. 10_000_000
    return parseInt(intString.replace(/_/g, ""), 10);
}

function envArray(arrayString) {
    // Example input "a,b,c" or just "a"
    return arrayString.split(",");
}

function envBool(boolString) {
    return boolString === "true";
}

export {
    getEnvString,
    envInt,
    envArray,
    envBool,
};
