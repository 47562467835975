import themes from "@/../public/themes/themes.json";
import store from "@/store";
import { mapActions } from "vuex";

export function applyThemeNative($vuetify, query = {}) {
    const { hostname } = window.location;
    const { themeName } = query;

    const themeDomain = themeName || hostname;
    const customTheme = themes.find((t) => t.domains?.includes(themeDomain));
    store.dispatch("themer/activateTheme", ({
        $vuetify,
        theme: customTheme || themes.find((t) => t.name === "default"),
    }));
}

export default {
    methods: {
        ...mapActions("themer", ["activateTheme"]),

        applyTheme() {
            applyThemeNative(this.$vuetify, this.$route.query);
        },
    },
};
