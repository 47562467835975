<template>
    <v-app :style="{ ...themeStyles, ...styles }" :class="`lang-${$i18n.locale}`">
        <v-main>
            <router-view />
        </v-main>
        <x-ybug v-if="accessLevel < 2" id="nycj1wtp9cw8hxzavtq7" />
    </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import chroma from "chroma-js";
import applyTheme from "@/mixins/applyTheme";
import loginCheck from "@/mixins/loginCheck";

export default {
    mixins: [applyTheme, loginCheck],
    computed: {
        ...mapState("themer", ["theme"]),
        ...mapState("authentication", ["xAuth"]),
        ...mapGetters("themer", ["themeStyles"]),
        ...mapGetters("authentication", ["accessLevel"]),

        styles() {
            const primary = this.theme?.colors?.primary || "#FFFFFF";
            return {
                "--colors-primary-alpha-1": chroma(primary).alpha(0.1).hex(),
                "--colors-primary-alpha-5": chroma(primary).alpha(0.5).hex(),
                "--colors-primary-alpha-9": chroma(primary).alpha(0.9).hex(),
            };
        },
    },
    created() {
        this.initialise();
        this.applyTheme();
    },
    methods: {
        ...mapActions("authentication", ["initialise", "logout"]),
    },
    async onIdle() {
        const loggedOut = await this.xAuth?.loggedIn() === false;
        const idlePage = this.$route.name === "admin-login" || this.$route.name === "customer-idle-session";

        if (loggedOut && idlePage) {
            return;
        }
        if (this.accessLevel === 0) {
            return;
        }
        if (this.accessLevel === 1) {
            await this.logout();
            await this.$router.push({ name: "admin-login" });
            return;
        }

        await this.logout();
        await this.$router.push({ name: "customer-idle-session" });
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap");

.v-application {
  font-family: 'Red Hat Display', sans-serif !important;
}

.verify-btn {
    background-color: white !important;
}

:root {
    --app-side-padding: 40px;
    --default-input-height: 44px;

    @media (max-width: 400px) {
        --app-side-padding: 25px;
    }

    @media (max-width: 340px) {
        --app-side-padding: 15px;
    }
}

.firebase-emulator-warning {
    display: none;
}

html,
body,
.v-application,
.v-application--wrap,
.v-main,
.v-main__wrap {
    height: 100% !important;
    max-height: 100% !important;
    width: 100vw;
}

.v-application--wrap {
    /* Vuetify fix */
    /* Without this Vuetify will mess up the screen sizing on mobile phones */
    min-height: auto !important;
}

body,
.v-application {
    background-color: var(--colors-background) !important;
}

.issue-div {
    position: relative;
    z-index: 20;
}

.multi-line-header {
    @media (max-width: 534px) {
        top: 50px !important;
    };
    @media (max-width: 295px) {
        top: 68px !important;
    };
}

h1 {
    text-transform: uppercase;
    font-size: 45px;
    line-height: .9em;
    font-weight: 400;
    padding-bottom: 20px;
    @media (max-height: 800px) {
        font-size: 35px;
        padding-bottom: 15px;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: var(--colors-primary);
}

/* Allows for the user of \n to create line breaks in i18n translations */
h1, h2, h3, h4, h5, h6, p, .panel-header {
    white-space: pre-line
}

body div.v-application.theme--light,
body div.v-application.theme--dark {
    color: var(--colors-secondary);

    .v-card.theme--light,
    .v-card.theme--dark {
        box-shadow: none !important;
        border: 1px solid var(--colors-secondary) !important;
        background-color: var(--colors-secondary) !important;
        border-radius: 10px;

        .v-card__title {
            color: var(--colors-accent-2) !important;
            background-color: transparent !important;
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 0 !important;
        }

        .v-card__text {
            padding: 16px !important;
            background-color: var(--colors-accent-2) !important;

            .v-input__slot {
                border-radius: 8px;

                fieldset {
                    border: 1px solid var(--colors-primary) !important;
                }
            }

            .v-input:last-of-type {
                margin-bottom: 0 !important;
            }
        }

        .v-card__actions {
            background-color: var(--colors-accent-2) !important;
            border-top: 0;
            padding: 4px 16px 14px 16px !important;

            .v-btn,
            .v-btn.primary,
            .v-btn.v-btn--disabled {
                background-color: var(--colors-accent-2) !important;
                border: 1px solid var(--colors-primary) !important;
                color: var(--colors-secondary) !important;
                box-shadow: none;
                font-weight: 700;
                border-radius: 6px;
                font-size: 13px;
                padding: 0 10px;
            }

            .v-btn.v-btn--disabled {
                opacity: .4;
            }
        }
    }

    .v-text-field.v-text-field--solo {
        .v-input__slot {
            border-radius: 8px;
            min-height: var(--default-input-height) !important;
            background-color: var(--colors-accent-2) !important;
            box-shadow: none;
            border: 1px solid var(--colors-primary) !important;
        }
    }
    .v-text-field--outlined.v-input fieldset {
        border-radius: 8px;
        min-height: var(--default-input-height) !important;
        background-color: var(--colors-accent-2) !important;
        box-shadow: none;
        border: 1px solid var(--colors-primary) !important;
    }
}

.v-btn__content:not(.v-snack .v-btn__content) {
    white-space: normal;
}

@keyframes ybugSlideIn {
    0% {
        transform: translateX(-50%) translateY(-100%);
    }
    100% {
        transform: translateX(-50%) translateY(0%);
    }
}

#__ybug-launcher {
    animation: ybugSlideIn 1s;
}
</style>
