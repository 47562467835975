import Vue from "vue";
import VueI18n from "vue-i18n";
import { getEnvString, envArray } from "@/envUtils";
import themes from "@/../public/themes/themes.json";
import _ from "lodash";

Vue.use(VueI18n);

let locale = getEnvString("$VUE_APP_I18N_LOCALE", "en");
const fallbackLocale = getEnvString("$VUE_APP_I18N_FALLBACK_LOCALE", "en");
const availableLanguages = envArray(getEnvString("$VUE_APP_I18N_AVAILABLE_LOCALES", "en"));

const savedLocale = localStorage.getItem("locale");
if (savedLocale && availableLanguages.includes(savedLocale)) {
    locale = savedLocale;
}

const { hostname } = window.location;
const urlParams = new URLSearchParams(window.location.search);
const themeName = urlParams.get("themeName");
const customTheme = themes.find((t) => t.domains?.includes(hostname));
const currentTheme = customTheme?.name || themeName || "default";

console.info(`Using theme: ${currentTheme}`);
const messages = {};
availableLanguages.forEach(async (lang) => {
    try {
        const themedMessage = await import(`../../public/themes/${currentTheme}/locales/${lang}.json`);
        const defaultMessage = await import(`../../public/themes/default/locales/${lang}.json`);
        messages[lang] = _.assign(defaultMessage, themedMessage);
    } catch (error) {
        if (error.message.includes(`Cannot find module './${currentTheme}/locales/${lang}.json`)) {
            console.error(`Language file for ${lang} in theme ${currentTheme} could not be loaded`, error);
            console.info(`Attempting to load language file for ${lang} in default theme`);
            messages[lang] = await import(`../../public/themes/default/locales/${lang}.json`);
        } else {
            console.error(`Language file for ${lang} could not be loaded`, error);
        }
    }
});

export const i18nSettings = {
    locale,
    fallbackLocale,
    messages,
};

export default new VueI18n(i18nSettings);
