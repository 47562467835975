import axios from "axios";
import Parser from "ua-parser-js";
import packageJson from "@/../package.json";

const { version } = packageJson;

export default {
    namespaced: true,
    state: () => ({
        apiEndpoint: "https://api.opsgenie.com/v2/alerts",
        apiKey: "ed37fa5f-438f-4c88-920e-efff00b91090",
    }),
    actions: {
        async reportError({ dispatch, rootGetters, rootState }) {
            const uaParser = new Parser();
            dispatch("send", {
                message: "A Customer has failed to open their locker",
                alias: `${rootGetters.location}: ${rootGetters.lockerId}`,
                details: {
                    type: "error",
                    meta: {
                        location: rootGetters.location,
                        id: rootGetters.lockerId,
                    },
                    data: {
                        browserDetails: uaParser.getResult(),
                        appVersion: version,
                        websocketStatus: rootState.authentication.connected,
                        loggedInStatus: await rootState.authentication.xAuth.loggedIn(),
                    },
                },
                priority: "P3",
            });
        },

        send({ state }, packet) {
            const options = {
                headers: {
                    "content-type": "application/json",
                    Authorization: `GenieKey ${state.apiKey}`,
                },
            };

            axios.post(
                state.apiEndpoint,
                packet,
                options,
            ).catch((error) => console.error(error));
        },
    },
};
